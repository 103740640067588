/* eslint-disable max-len */
import { BaseBulkOperationsModel } from '../BaseBulkOperationsModel';
import { NotificationSignalRModel } from '@/signalRRequest/Notification/NotificationSignalRModel';
export class NotificationBulkOperation extends BaseBulkOperationsModel {
  constructor(rootObj: any, $store: any, $t: any) {
    super();
    const signalR: NotificationSignalRModel = new NotificationSignalRModel(rootObj, $store, $t);

    this.arrBulkOperations.push({ Id: 1, Name: 'Export Dialogues', IconUrl: require('@/assets/Images/export-dialogue-icon.svg'), IconPadding: 'padding:17px 19px 17px 17px;', Description: 'Export responses from attendees in excel file', ConfirmationMessage: '', ConfirmationMessageSubTitle: '', objModel: signalR.ExportSelectedNotificationSignalRModelRequest, ShowConfirmationPopup: false });

    this.arrBulkOperations.push({ Id: 2, Name: 'Delete Dialogues', IconUrl: require('@/assets/Images/delete-dialogues-icon.svg'), IconPadding: 'padding:17px;', Description: 'Delete selected Dialogue Items', ConfirmationMessage: 'Notifications.SureDeleteDialogueItems', ConfirmationMessageSubTitle: '', objModel: signalR.DeleteSelectedNotificationSignalRModelRequest, ShowConfirmationPopup: true });

    this.arrBulkOperations.push({ Id: 4, Name: 'Change Folders', IconUrl: require('@/assets/Images/change-folder-icon.svg'), IconPadding: 'padding:17px 12px 17px 17px', Description: 'Change folder destination for selected item', ConfirmationMessage: '', ConfirmationMessageSubTitle: '', objModel: signalR.BulkAssignFolderSignalRModelRequest, ShowConfirmationPopup: false });

    this.arrBulkOperations.push({ Id: 3, Name: 'Clear Responses', IconUrl: require('@/assets/Images/clear-responses-icon.svg'), IconPadding: 'padding:17px 19px 17px 12px;', Description: 'Clear Responses of selected Dialogues', ConfirmationMessage: 'Notifications.SureClearResponsesDialogueItems', ConfirmationMessageSubTitle: '', objModel: signalR.ClearResponsesSelectedNotificationItemsSignalRModelRequest, ShowConfirmationPopup: true });
  }
}
