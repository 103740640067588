import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2117d21c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "scroll-top grid-listing" }
const _hoisted_2 = { class: "flex-container" }
const _hoisted_3 = { class: "right-container-nav" }
const _hoisted_4 = { class: "width25" }
const _hoisted_5 = { class: "width50" }
const _hoisted_6 = { class: "main-heading primary-heading" }
const _hoisted_7 = { class: "add-btn-position" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotificationExportPane = _resolveComponent("NotificationExportPane")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("button", {
              class: "btn btn-secondary btn-size-default",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.CloseExport()))
            }, _toDisplayString(_ctx.$t("Button.Close")), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t("Dialogue.Export")), 1)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("button", {
              class: "btn btn-primary btn-size-default",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.Export()))
            }, _toDisplayString(_ctx.$t("Dialogue.Export")), 1)
          ])
        ]),
        _createVNode(_component_NotificationExportPane, {
          "participant-segments-export-pane": _ctx.localparticipantSegments,
          "is-scrollable": false,
          onExportTypeSelected: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onExportOptionsSelected($event)))
        }, null, 8, ["participant-segments-export-pane"])
      ])
    ])
  ]))
}