import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-50e45fc1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  id: "pills-results-tab",
  class: "nav-link results-pill navigation-tab",
  "data-toggle": "pill",
  href: "#pills-results",
  role: "tab",
  "aria-controls": "pills-results",
  "aria-selected": "false"
}
const _hoisted_3 = { class: "display-inline-flex" }
const _hoisted_4 = {
  id: "pills-tabContent",
  class: "tab-content"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { key: 3 }
const _hoisted_9 = { key: 4 }
const _hoisted_10 = { key: 5 }
const _hoisted_11 = { key: 6 }
const _hoisted_12 = { key: 7 }
const _hoisted_13 = {
  id: "pills-results",
  class: "tab-pane fade margin-top-20 margin-bottom-20",
  role: "tabpanel",
  "aria-labelledby": "pills-results-tab",
  style: {"opacity":"1"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotificationInformationMessageEditPane = _resolveComponent("NotificationInformationMessageEditPane")!
  const _component_NotificationSingleChoiceQuestionEditPane = _resolveComponent("NotificationSingleChoiceQuestionEditPane")!
  const _component_NotificationMultipleChoiceQuestionEditPane = _resolveComponent("NotificationMultipleChoiceQuestionEditPane")!
  const _component_NotificationSingleResponseTextFeedbackEditPane = _resolveComponent("NotificationSingleResponseTextFeedbackEditPane")!
  const _component_NotificationMultiResponseTextFeedbackEditPane = _resolveComponent("NotificationMultiResponseTextFeedbackEditPane")!
  const _component_NotificationPickCorrectAnswerEditPane = _resolveComponent("NotificationPickCorrectAnswerEditPane")!
  const _component_NotificationRankOptionItemEditPane = _resolveComponent("NotificationRankOptionItemEditPane")!
  const _component_NotificationScaleStatementEditPane = _resolveComponent("NotificationScaleStatementEditPane")!
  const _component_NotificationResultsDetails = _resolveComponent("NotificationResultsDetails")!

  return (_ctx.localNotificationItem != null)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("ul", {
          id: "pills-tab",
          class: _normalizeClass(["nav nav-pills center-pills", { 'showtabs': _ctx.localNotificationItem.Id > 0 && _ctx.notificationTypeId !== _ctx.notificationEnum.InformationMessage,'collapsetabs': _ctx.localNotificationItem.Id == 0 || _ctx.notificationTypeId == _ctx.notificationEnum.InformationMessage}]),
          role: "tablist"
        }, [
          _createElementVNode("li", {
            class: _normalizeClass(["nav-item", { 'navaigation-pills-width': !_ctx.isMobileView, 'navaigation-pills-width-mobile': _ctx.isMobileView}])
          }, [
            _createElementVNode("a", {
              id: "pills-settings-tab",
              class: _normalizeClass(["nav-link active settings-pill navigation-tab", { 'active': _ctx.localNotificationItem.NotificationFeedbacks >= 0}]),
              "data-toggle": "pill",
              href: "#pills-settings",
              role: "tab",
              "aria-controls": "pills-settings",
              "aria-selected": "true"
            }, _toDisplayString(_ctx.$t("Settings")), 3)
          ], 2),
          _createElementVNode("li", {
            class: _normalizeClass(["nav-item", { 'navaigation-pills-width': !_ctx.isMobileView, 'navaigation-pills-width-mobile': _ctx.isMobileView}])
          }, [
            _createElementVNode("a", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("Dialogues.Results", {count: _ctx.localNotificationItem.NotificationFeedbacks})), 1)
              ])
            ])
          ], 2)
        ], 2),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", {
            id: "pills-settings",
            class: _normalizeClass(["tab-pane fade show active", {'edit-pane': _ctx.localNotificationItem.NotificationFeedbacks >= 0}]),
            role: "tabpanel",
            "aria-labelledby": "pills-settings-tab"
          }, [
            (_ctx.notificationTypeId === _ctx.notificationEnum.InformationMessage)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_NotificationInformationMessageEditPane, {
                    "selected-notification-item": _ctx.localNotificationItem,
                    folders: _ctx.folders,
                    filters: _ctx.filters,
                    "available-custom-category-folders": _ctx.availableCustomCategoryFolders,
                    "available-custom-category-items": _ctx.availableCustomCategoryItems,
                    "available-program-items": _ctx.availableProgramItems
                  }, null, 8, ["selected-notification-item", "folders", "filters", "available-custom-category-folders", "available-custom-category-items", "available-program-items"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.notificationTypeId === _ctx.notificationEnum.SingleChoiceQuestion)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_NotificationSingleChoiceQuestionEditPane, {
                    "selected-notification-item": _ctx.localNotificationItem,
                    folders: _ctx.folders,
                    filters: _ctx.filters
                  }, null, 8, ["selected-notification-item", "folders", "filters"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.notificationTypeId === _ctx.notificationEnum.MultipleChoiceQuestion)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(_component_NotificationMultipleChoiceQuestionEditPane, {
                    "selected-notification-item": _ctx.localNotificationItem,
                    folders: _ctx.folders,
                    filters: _ctx.filters
                  }, null, 8, ["selected-notification-item", "folders", "filters"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.notificationTypeId === _ctx.notificationEnum.SingleRespponseTextFeedback)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createVNode(_component_NotificationSingleResponseTextFeedbackEditPane, {
                    "selected-notification-item": _ctx.localNotificationItem,
                    folders: _ctx.folders,
                    filters: _ctx.filters
                  }, null, 8, ["selected-notification-item", "folders", "filters"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.notificationTypeId === _ctx.notificationEnum.MultipleRespponseTextFeedback)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createVNode(_component_NotificationMultiResponseTextFeedbackEditPane, {
                    "selected-notification-item": _ctx.localNotificationItem,
                    folders: _ctx.folders,
                    filters: _ctx.filters
                  }, null, 8, ["selected-notification-item", "folders", "filters"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.notificationTypeId === _ctx.notificationEnum.PickCorrect)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createVNode(_component_NotificationPickCorrectAnswerEditPane, {
                    "selected-notification-item": _ctx.localNotificationItem,
                    folders: _ctx.folders,
                    filters: _ctx.filters
                  }, null, 8, ["selected-notification-item", "folders", "filters"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.notificationTypeId === _ctx.notificationEnum.RankOptionItems)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _createVNode(_component_NotificationRankOptionItemEditPane, {
                    "selected-notification-item": _ctx.localNotificationItem,
                    folders: _ctx.folders,
                    filters: _ctx.filters
                  }, null, 8, ["selected-notification-item", "folders", "filters"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.notificationTypeId === _ctx.notificationEnum.ScaleStatements)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _createVNode(_component_NotificationScaleStatementEditPane, {
                    "selected-notification-item": _ctx.localNotificationItem,
                    folders: _ctx.folders,
                    filters: _ctx.filters
                  }, null, 8, ["selected-notification-item", "folders", "filters"])
                ]))
              : _createCommentVNode("", true)
          ], 2),
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_NotificationResultsDetails, { "selected-notification-item": _ctx.localNotificationItem }, null, 8, ["selected-notification-item"])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}