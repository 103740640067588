
import { Component, Prop, toNative, Vue, Watch } from 'vue-facing-decorator';
import { NotificationItem } from '@/entities/Notification/NotificationItem';
import { BrowserSettingsInfo } from '@/entities/BrowserSettingsInfo';
import { UserFeedbackModel } from '@/entities/Notification/NotificationResults';
import { StoreHelper } from '@/store/StoreHelper';
import ModalPopup from '@/components/Common/ModalPopup.vue';
import Paginate from 'vuejs-paginate-next';
import moment from 'moment-timezone';
import NotificationEnum from '@/enums/NotificationEnum';
import { FetchData, Root, Store } from '@/main';
import RemoveNotificationFeedback from '@/entities/Emits/Notification/RemoveNotificationFeedback';

@Component({
  components: {
    Paginate,
    ModalPopup,
  },
})
class NotificationIndividualResponses extends Vue {
  @Prop() private selectedNotificationItem!: NotificationItem;
  private localNotificationItem: NotificationItem = NotificationItem.createEmpty();
  private storeHelper: StoreHelper = new StoreHelper(Store);
  private showIndividualResponses = false;
  private isUsersAnonymous = false;
  private timeZones: string = this.storeHelper.GetActiveEventIanaTimeZoneFromStore();
  private modalPopupVisible = false;
  private showCloseButton = true;
  private modalPopupContent = '';
  private modalPopupShowCancelButton = true;
  private isIEbrowser = false;
  private dateFormat: Intl.DateTimeFormat = new Intl.DateTimeFormat();
  private userResponses: UserFeedbackModel[] | [] = [];
  private page = 1;
  private pageCount = 3;
  private chunkSize = 0;
  // page size is the number of items to be displayed per page
  private pageSizeForAllItemsExceptTextListing = 5;
  private pageSizeForTextListingItem = 10;
  private selectesResponseIdTodelete = 0;
  private notificationTypeId = -1;
  private arrowUpImage = require('@/assets/Images/arrow-up.svg');
  private arrowDownImage = require('@/assets/Images/arrow-down.svg');
  private trashImage = require('@/assets/Images/Delete-icon.svg');
  private isArrowUp = false;
  private paginationPageMargin = 2;
  private pagiantionPreviewText = 'Prev';
  private pagiantionNextText = 'Next';
  private paginationPageRange = 3;
  private isSelectedItemTypeTextListing = false;
  private totalUsersFeedbacksCount = 0;
  private mounted() {
    if (navigator.appName === 'Microsoft Internet Explorer' ||  !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/))) {
      this.isIEbrowser = true;
    }
    this.initializeTimeFormat();
    this.localNotificationItem = Object.assign({},this.selectedNotificationItem);
    this.SetPaginationData();
  }
  // this function helps in setting and updating the pagintion data
  private SetPaginationData() {
    this.GetNumberOfResponses();
    // updating this helps in envoking the correct api corresponding to type of dialogue item
    this.notificationTypeId = this.localNotificationItem.NotificationTypeId;
    this.isUsersAnonymous = this.localNotificationItem.EnableResponseAnonymously;
    this.isSelectedItemTypeTextListing = this.localNotificationItem.SelectedChartTypeId === 1 ? true : false;
    this.chunkSize = this.isSelectedItemTypeTextListing ? this.pageSizeForTextListingItem : this.pageSizeForAllItemsExceptTextListing;// 10 items will be shown for listing type and 5 for others in table
    this.pageCount = Math.floor(this.totalUsersFeedbacksCount / this.chunkSize) + ((this.totalUsersFeedbacksCount % this.chunkSize) > 0 ? 1 : 0);
    this.clickCallback(1);
  }
  // this helps in getting the current number of user feedbacks available
  private GetNumberOfResponses() {
    if(this.localNotificationItem.UserFeedback !== undefined) {
      this.totalUsersFeedbacksCount = this.localNotificationItem.UserFeedback.length;
    }
  }
  private RemoveFeedback(responseId: number) {
    const objIndex = this.localNotificationItem.UserFeedback!.findIndex(
      (obj: UserFeedbackModel) => obj.Id === responseId,
    );
    if (objIndex > -1) {
      if(this.localNotificationItem !== null && this.localNotificationItem !== undefined && this.localNotificationItem.UserFeedback !== null && this.localNotificationItem.UserFeedback !== undefined) {
        delete this.localNotificationItem.UserFeedback[objIndex];
      }
    }
    Root.emit('notification-show-overlay', true);
    if (!this.localNotificationItem.IsSurvey) {
      Root.emit('reload-notification-detail');
    } else {
      Root.emit('reload-sub-notification-detail', this.localNotificationItem);
    }
    this.GetNumberOfResponses();
    const removeFeedbacks: RemoveNotificationFeedback = RemoveNotificationFeedback.createEmpty();
    removeFeedbacks.FeedbacksCount = this.totalUsersFeedbacksCount;
    removeFeedbacks.Id = this.localNotificationItem.Id;
    Root.emit('notification-feedback-removed', removeFeedbacks);
  }
  private ConfirmClick(confirmed: boolean) {
    this.modalPopupVisible = false;
    if (confirmed) {
      this.ClearResponse();
    }
  }
  private ClearSingleResponseClicked(respId: number) {
    this.showCloseButton = true;
    this.modalPopupContent = this.$t('Dialogue.SingleResponseDelete').toString();
    this.modalPopupVisible = true;
    this.selectesResponseIdTodelete = respId;
  }
  private ClearResponse() {
    if (this.notificationTypeId === NotificationEnum.RankOptionItems ||
         this.notificationTypeId === NotificationEnum.ScaleStatements) {
      FetchData('/Notification/DeleteRankingReposne', JSON.stringify({
        responseId: this.selectesResponseIdTodelete,
      }))
        .then((response) => {
          if (response.Success === true) {
            this.RemoveFeedback(this.selectesResponseIdTodelete);
          }
        })
        .catch(() => {
          // Log error if required
        });
    } else {
      FetchData('/Notification/DeleteSingleReposne', JSON.stringify({
        responseId: this.selectesResponseIdTodelete,
      }))
        .then((response) => {
          if (response.Success === true) {
            this.RemoveFeedback(this.selectesResponseIdTodelete);
          }
        })
        .catch(() => {
          // Log error if required
        });
    }
  }
  private clickCallback(pageNum: any) {
    if(this.localNotificationItem.UserFeedback !== undefined) {
      this.userResponses = Array.from(this.localNotificationItem.UserFeedback);
      this.userResponses =  this.userResponses.splice(pageNum*this.chunkSize - this.chunkSize, this.chunkSize);
    }
  }
  private formatTime(date: string): string {
    const datepublished: Date = moment(date).toDate();
    if (!this.isIEbrowser) {
      return this.dateFormat.format(datepublished).toUpperCase();
    } else {
      const ieDateForamt = moment(datepublished).tz(this.timeZones).format('DD-MM-YYYY, h:mm a');
      return ieDateForamt;
    }
  }
  private initializeTimeFormat() {
    const browsersettings: BrowserSettingsInfo = this.storeHelper.GetBrowserSettingsFromStore();
    if (this.timeZones !== '' && this.timeZones !== null && !this.isIEbrowser) {
      this.dateFormat = new Intl.DateTimeFormat(browsersettings.locale, {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        timeZone: this.timeZones,
      });
    } else {
      this.dateFormat = new Intl.DateTimeFormat(browsersettings.locale, {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      });
    }
  }
  // this helps in showing and hiding the table corresponding to up down arrow
  private ShowIndividualResponseTable() {
    this.isArrowUp = !this.isArrowUp;
    this.showIndividualResponses = this.isArrowUp ? true : false;
  }
  @Watch('selectedNotificationItem',{deep: true})
  private CheckChangeInSelectedNotificationItem(newVal: NotificationItem) {
    this.localNotificationItem = newVal;
    this.SetPaginationData();
  }
}
export default toNative(NotificationIndividualResponses);
