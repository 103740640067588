import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "div-linked-dialogue" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotificationHeaderPane = _resolveComponent("NotificationHeaderPane")!
  const _component_NotificationFooterPane = _resolveComponent("NotificationFooterPane")!
  const _component_LinkNotificationWithEntity = _resolveComponent("LinkNotificationWithEntity")!
  const _component_AddInformationWithEntity = _resolveComponent("AddInformationWithEntity")!
  const _component_AddProgramWithEntity = _resolveComponent("AddProgramWithEntity")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.showLinkInformation && !_ctx.showLinkProgram)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", null, [
            _createVNode(_component_NotificationHeaderPane, {
              "selected-notification-item": _ctx.localNotificationItem,
              onSetHeaderProperties: _cache[0] || (_cache[0] = ($event: any) => (_ctx.SetlocalNotificationHeaderProperties($event)))
            }, null, 8, ["selected-notification-item"])
          ]),
          (!_ctx.localNotificationItem.IsSurvey)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_NotificationFooterPane, {
                  "is-create-new": _ctx.localNotificationItem.Id == 0 ? true : false,
                  folders: _ctx.folders,
                  folder: _ctx.localNotificationItem.Folder,
                  filter: _ctx.localNotificationItem.Filter,
                  "filter-id": _ctx.localNotificationItem.FilterId,
                  accesscode: _ctx.localNotificationItem.Code,
                  filters: _ctx.filters,
                  onSetGroup: _cache[1] || (_cache[1] = ($event: any) => (_ctx.SetSelectedFolder($event))),
                  onSetAccessCode: _cache[2] || (_cache[2] = ($event: any) => (_ctx.SetAccessCode($event))),
                  onSetFilter: _cache[3] || (_cache[3] = ($event: any) => (_ctx.SetFilter($event)))
                }, null, 8, ["is-create-new", "folders", "folder", "filter", "filter-id", "accesscode", "filters"])
              ]))
            : _createCommentVNode("", true),
          (!_ctx.localNotificationItem.IsSurvey)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_LinkNotificationWithEntity, {
                    "linked-information": _ctx.availableCustomCategoryItems,
                    "selected-entity-id": _ctx.localNotificationItem.Id,
                    "linked-programs": _ctx.availableProgramItems,
                    onClickAddInformation: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showAttachInformation($event))),
                    onClickAddProgram: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showAttachInformation($event)))
                  }, null, 8, ["linked-information", "selected-entity-id", "linked-programs"])
                ])
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.showLinkInformation)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_AddInformationWithEntity, {
            "selected-entity-id": _ctx.localNotificationItem.Id,
            "all-informations": _ctx.localAllInformations,
            "custom-category-folders": _ctx.availableCustomCategoryFolders,
            "is-bulk-operation": false,
            "entity-type": _ctx.entityType,
            onSelectBack: _cache[6] || (_cache[6] = ($event: any) => (_ctx.hideAttachInformation($event))),
            onLinkedCategoryAddProgramNotification: _cache[7] || (_cache[7] = ($event: any) => (_ctx.SendNotificationRequestToQueueForEntity($event)))
          }, null, 8, ["selected-entity-id", "all-informations", "custom-category-folders", "entity-type"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.showLinkProgram)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_AddProgramWithEntity, {
            "selected-entity-id": _ctx.localNotificationItem.Id,
            "all-programs": _ctx.localAllPrograms,
            "is-bulk-operation": false,
            "entity-type": _ctx.entityType,
            onSelectBack: _cache[8] || (_cache[8] = ($event: any) => (_ctx.hideAttachProgram($event))),
            onLinkedCategoryAddProgramNotification: _cache[9] || (_cache[9] = ($event: any) => (_ctx.SendNotificationRequestToQueueForEntity($event)))
          }, null, 8, ["selected-entity-id", "all-programs", "entity-type"])
        ]))
      : _createCommentVNode("", true)
  ]))
}