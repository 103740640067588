import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9ac206ec"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "NotificationHeaderPane" }
const _hoisted_2 = { class: "margin-bottom" }
const _hoisted_3 = { class: "top-margin-in-fields" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = ["value", "selected"]
const _hoisted_6 = { class: "top-margin-in-fields" }
const _hoisted_7 = {
  for: "Description",
  class: "top-margin-in-fields"
}
const _hoisted_8 = {
  id: "content-div",
  class: "width100"
}
const _hoisted_9 = { id: "textarea-div" }
const _hoisted_10 = { class: "top-margin-in-fields" }
const _hoisted_11 = {
  for: "Description",
  class: "top-margin-in-fields"
}
const _hoisted_12 = {
  id: "content-div",
  class: "width100"
}
const _hoisted_13 = { id: "textarea-div" }
const _hoisted_14 = { class: "top-margin-in-fields" }
const _hoisted_15 = { class: "inline-flex description" }
const _hoisted_16 = { class: "padding-1 margin-left-5" }
const _hoisted_17 = {
  key: 0,
  id: "logo-div"
}
const _hoisted_18 = { class: "img-select margin-right-10" }
const _hoisted_19 = { class: "div-notification-header-pane-img" }
const _hoisted_20 = ["src"]
const _hoisted_21 = { class: "edit-link-image" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalPopup = _resolveComponent("ModalPopup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.modalPopupVisible)
      ? (_openBlock(), _createBlock(_component_ModalPopup, {
          key: 0,
          "show-modal-popup": _ctx.modalPopupVisible,
          "body-heading": _ctx.modalPopupContent,
          "show-cancel-button": _ctx.modalPopupShowCancelButton,
          "cancel-button-text": _ctx.modalPopupCancelButtonText,
          "confirm-button-text": _ctx.modalPopupConfirmButtonText,
          "show-close-button": _ctx.showCloseButton,
          onConfirmClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ConfirmClick($event)))
        }, null, 8, ["show-modal-popup", "body-heading", "show-cancel-button", "cancel-button-text", "confirm-button-text", "show-close-button"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('Dialogue.SelectDialogueType')), 1),
          _createElementVNode("select", {
            id: "selNotificationtype",
            class: "form-control question-type-width selectBox",
            disabled: _ctx.localNotificationItem.Id != 0 && !_ctx.localNotificationItem.IsNewSurveyItem,
            onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.OnNotificationtypeChange($event)))
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notificationTypes.arrNotificationOptions, (notificationtype) => {
              return (_openBlock(), _createElementBlock("option", {
                key: notificationtype.Id,
                value: notificationtype.Id,
                selected: notificationtype.Name == _ctx.selectedNotificationTypeDescription
              }, _toDisplayString(_ctx.$t(notificationtype.Name)), 9, _hoisted_5))
            }), 128))
          ], 40, _hoisted_4)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("Title")), 1),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _withDirectives(_createElementVNode("textarea", {
              id: "notificationTitle",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.v$.localNotificationItem.Title.$model) = $event)),
              class: _normalizeClass(["form-control textarea-title textarea-size", _ctx.status(_ctx.v$.localNotificationItem.Title)]),
              maxlength: "150",
              onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.change()))
            }, null, 34), [
              [
                _vModelText,
                _ctx.v$.localNotificationItem.Title.$model,
                void 0,
                { trim: true }
              ]
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t("Description")), 1),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _withDirectives(_createElementVNode("textarea", {
              id: "description",
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localNotificationItem.Description) = $event)),
              class: "form-control textarea-title textarea-size-description",
              onInput: _cache[5] || (_cache[5] = ($event: any) => (_ctx.change()))
            }, null, 544), [
              [
                _vModelText,
                _ctx.localNotificationItem.Description,
                void 0,
                { trim: true }
              ]
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("label", _hoisted_15, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.IsImageVisible) = $event)),
            type: "checkbox",
            name: "imageVisible",
            class: "checkbox"
          }, null, 512), [
            [_vModelCheckbox, _ctx.IsImageVisible]
          ]),
          _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t("Dialogue.IncludeImage")), 1)
        ]),
        (_ctx.IsImageVisible)
          ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("img", {
                    class: "notification-header-page-icon-img top-margin-in-fields",
                    src: _ctx.GetImageUrl(),
                    onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.clickOnImage(true)))
                  }, null, 8, _hoisted_20)
                ]),
                _createElementVNode("div", _hoisted_21, [
                  _createElementVNode("a", {
                    class: "hyperLink",
                    onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.clickOnImage(true)))
                  }, _toDisplayString(_ctx.$t('Button.Edit')), 1)
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}