import HighchartHelper from '@/helper/HighchartHelper';
export class CommonHighchartData {
  public static createEmpty(): CommonHighchartData {
    return new CommonHighchartData();
  }
  public static SetBarChartData(ChartCategories: string[],chartData: any[], chartType: string) {
    let chartObject: CommonHighchartData = CommonHighchartData.createEmpty();
    chartObject = SetChartCategoriesAndData(ChartCategories,chartData,chartType);
    const maxIntevalConstant = 10;
    let maxChartOption = 10;
    chartObject.ChartData = [];
    chartData.map((num) => {
      chartObject.ChartData.push(Number(num.percentage));
      maxChartOption = maxChartOption > num.percentage ? maxChartOption : num.percentage;
      chartObject.totalCount += num.votes;
    });
    chartObject.pieChartData = chartData.map((data,index)=> ({ name: ChartCategories[index], y:data.percentage}));
    chartObject.MaxInterval = maxIntevalConstant*(Math.round(maxChartOption/maxIntevalConstant) + 1);
    chartObject.TickInterval = chartObject.MaxInterval > 50 ? 10 : 5;
    return chartObject;
  }
  public static SetScalingChartData(ChartCategories: string[],chartData: any[], chartType: string, MaxInterval: number, MinInterval: number) {
    let chartObject: CommonHighchartData = CommonHighchartData.createEmpty();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const maxValue = Math.max(...chartData);
    chartObject = SetChartCategoriesAndData(ChartCategories,chartData,chartType);
    chartObject.MaxInterval = Math.ceil(maxValue) >= MaxInterval ? Math.ceil(maxValue) : MaxInterval;
    chartObject.MarginRight = Math.floor(maxValue) === chartObject.MaxInterval ? 20 : 0;
    chartObject.MinInterval = MinInterval;
    chartObject.TickInterval = 1;
    return chartObject;
  }
  public static SetRankingChartData(ChartCategories: string[],chartData: any[], chartType: string) {
    let chartObject: CommonHighchartData = CommonHighchartData.createEmpty();
    chartObject = SetChartCategoriesAndData(ChartCategories,chartData,chartType);
    return chartObject;
  }
  constructor(
    public ChartType: string = '',
    public ChartData: number[] = [],
    public ChartCategories: string[] = [],
    public ChartHeight: number = 0,
    public Title: string = '',
    public BackgroundImgUrl: string = '',
    public BackgroundColor: string = '',
    public LogoUrl: string = '',
    public FontSize: string = '',
    public FontColor: string = HighchartHelper.FontColor,
    public FontWeight: any = HighchartHelper.FontWeight,
    public FontFamily: string = HighchartHelper.FontFamily,
    public LineHeight: number = 1.2,
    public MinInterval: number = 0,
    public MaxInterval: number = 0,
    public TickInterval: number = 0,
    public Colors: string[] = HighchartHelper.Colors,
    public IsAxisVisible: boolean = true,
    public MarginRight: number = 0,
    public pieChartData: PieChartData[] = [],
    public totalCount: number = 0,
  ){}
}
function SetChartCategoriesAndData(ChartCategories: string[],chartData: any[], chartType: string) {
  const chartObject: CommonHighchartData = CommonHighchartData.createEmpty();
  chartObject.ChartCategories = ChartCategories;
  chartObject.ChartData = chartData;
  chartObject.ChartType = chartType;
  chartObject.FontSize = GetFontSize();
  chartObject.ChartHeight = GetChartHeight(chartData.length)!;
  chartObject.MarginRight = window.innerWidth < 480 ? 20 : 0;
  return chartObject;
}
function GetChartHeight(length: number) {
  if (length <= 4) {
    return 300;
  } else if (length > 4 && length <= 6) {
    return 500;
  } else if (length > 6 && length <= 10) {
    return 600;
  } else if (length > 10 && length <= 15) {
    return 1000;
  } else if (length > 15 && length <= 20) {
    return 1200;
  } else if (length >= 25) {
    return 1400;
  }
}
function GetFontSize(): string {
  return window.innerWidth > 768 && window.innerHeight < window.innerWidth ? window.innerHeight * 0.016 + 'px' : window.innerHeight * 0.014 + 'px';
}
export class PieChartData {
  public static createEmpty(): PieChartData {
    return new PieChartData();
  }
  constructor(
    public name: string = '',
    public y: number = 0,
  ){}
}
