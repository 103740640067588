import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-821f7e34"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "margin-top-20 margin-bottom-20"
}
const _hoisted_2 = { class: "editPane scroll-top" }
const _hoisted_3 = { class: "top-margin-in-fields" }
const _hoisted_4 = { class: "addtwooptionsmsg" }
const _hoisted_5 = { id: "lowLabelDiv" }
const _hoisted_6 = { class: "dimensionLabel" }
const _hoisted_7 = { class: "dimensionvalue" }
const _hoisted_8 = ["id"]
const _hoisted_9 = ["id"]
const _hoisted_10 = ["id"]
const _hoisted_11 = {
  key: 0,
  class: "top-margin-in-fields"
}
const _hoisted_12 = {
  key: 1,
  id: "midLabelDiv"
}
const _hoisted_13 = ["index"]
const _hoisted_14 = ["id"]
const _hoisted_15 = ["id", "onUpdate:modelValue"]
const _hoisted_16 = ["id", "onUpdate:modelValue"]
const _hoisted_17 = { id: "highLabelDiv" }
const _hoisted_18 = { class: "dimensionLabel bold-text" }
const _hoisted_19 = { class: "dimensionvalue bold-text" }
const _hoisted_20 = ["id"]
const _hoisted_21 = ["id"]
const _hoisted_22 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.localDimensions.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t("Dialogue.Dimensions")), 1),
              _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t("Dialogue.DimensionsRecommend")), 1)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", null, [
                  _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t("Dialogue.LowLabel")), 1),
                  _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("Dialogue.LowValue")), 1)
                ]),
                _createElementVNode("div", {
                  id: _ctx.localDimensions[0].Id,
                  class: _normalizeClass([{ mobileViewOptionsWidth: _ctx.isMobileView, width100: !_ctx.isMobileView }, "drag-item inline-flex"])
                }, [
                  _withDirectives(_createElementVNode("input", {
                    id: _ctx.localDimensions[0].Id,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localDimensions[0].NotificationDimensionLabel) = $event)),
                    type: "text",
                    class: "form-control dimensionLabel",
                    maxlength: "30",
                    onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.dimensionLabelChange($event)))
                  }, null, 40, _hoisted_9), [
                    [_vModelText, _ctx.localDimensions[0].NotificationDimensionLabel]
                  ]),
                  _withDirectives(_createElementVNode("input", {
                    id: _ctx.localDimensions[0].Id,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localDimensions[0].NotificationDimensionValue) = $event)),
                    type: "number",
                    class: "form-control dimensionvalue",
                    maxlength: "2",
                    oninput: "validity.valid||(value='');",
                    min: "0",
                    max: "10",
                    onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.lowLabeldimensionValueChange($event)))
                  }, null, 40, _hoisted_10), [
                    [_vModelText, _ctx.localDimensions[0].NotificationDimensionValue]
                  ])
                ], 10, _hoisted_8)
              ]),
              (_ctx.noMidLevelDimension)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    (_ctx.localDimensions.length > 1)
                      ? (_openBlock(), _createElementBlock("a", {
                          key: 0,
                          class: "hyperLink",
                          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.ShowMidLabels($event)))
                        }, _toDisplayString(_ctx.$t("Dialogue.MidlablesValues")), 1))
                      : _createCommentVNode("", true),
                    _createElementVNode("i", {
                      id: "midLabelIcon",
                      class: _normalizeClass({ 'fa fa-angle-down': _ctx.midLevelDimensionsVisible, 'fa fa-angle-up': !_ctx.midLevelDimensionsVisible}),
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.ShowMidLabels($event)))
                    }, null, 2)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.localDimensions.length > 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localDimensions.slice(1, _ctx.localDimensions.length - 1), (item, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: index,
                        class: "margin-top-10",
                        index: index
                      }, [
                        _createElementVNode("div", {
                          id: item.Id,
                          class: _normalizeClass([{ mobileViewOptionsWidth: _ctx.isMobileView, width100: !_ctx.isMobileView }, "drag-item inline-flex"])
                        }, [
                          _withDirectives(_createElementVNode("input", {
                            id: item.Id,
                            "onUpdate:modelValue": ($event: any) => ((item.NotificationDimensionLabel) = $event),
                            type: "text",
                            class: "form-control dimensionLabel",
                            maxlength: "30",
                            onInput: _cache[6] || (_cache[6] = ($event: any) => (_ctx.dimensionLabelChange($event)))
                          }, null, 40, _hoisted_15), [
                            [_vModelText, item.NotificationDimensionLabel]
                          ]),
                          _withDirectives(_createElementVNode("input", {
                            id: item.Id,
                            "onUpdate:modelValue": ($event: any) => ((item.NotificationDimensionValue) = $event),
                            type: "number",
                            class: "form-control dimensionvalue",
                            maxlength: "30",
                            disabled: true,
                            min: "0",
                            max: "10"
                          }, null, 8, _hoisted_16), [
                            [_vModelText, item.NotificationDimensionValue]
                          ])
                        ], 10, _hoisted_14)
                      ], 8, _hoisted_13))
                    }), 128))
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("div", null, [
                  _createElementVNode("label", _hoisted_18, _toDisplayString(_ctx.$t("Dialogue.HighLabel")), 1),
                  _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.$t("Dialogue.HighValue")), 1)
                ]),
                _createElementVNode("div", {
                  id: _ctx.localDimensions[_ctx.localDimensions.length - 1].Id,
                  class: _normalizeClass([{ mobileViewOptionsWidth: _ctx.isMobileView, width100: !_ctx.isMobileView }, "drag-item inline-flex"])
                }, [
                  _withDirectives(_createElementVNode("input", {
                    id: _ctx.localDimensions[_ctx.localDimensions.length - 1].Id,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.localDimensions[_ctx.localDimensions.length - 1].NotificationDimensionLabel) = $event)),
                    type: "text",
                    class: "form-control dimensionLabel bold-text",
                    maxlength: "30",
                    onInput: _cache[8] || (_cache[8] = ($event: any) => (_ctx.dimensionLabelChange($event)))
                  }, null, 40, _hoisted_21), [
                    [_vModelText, _ctx.localDimensions[_ctx.localDimensions.length - 1].NotificationDimensionLabel]
                  ]),
                  _withDirectives(_createElementVNode("input", {
                    id: _ctx.localDimensions[_ctx.localDimensions.length - 1].Id,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.localDimensions[_ctx.localDimensions.length - 1].NotificationDimensionValue) = $event)),
                    type: "number",
                    class: "form-control dimensionvalue",
                    maxlength: "2",
                    oninput: "validity.valid||(value='');",
                    min: "0",
                    max: "10",
                    onChange: _cache[10] || (_cache[10] = ($event: any) => (_ctx.highLabeldimensionValueChange($event)))
                  }, null, 40, _hoisted_22), [
                    [_vModelText, _ctx.localDimensions[_ctx.localDimensions.length - 1].NotificationDimensionValue]
                  ])
                ], 10, _hoisted_20)
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}