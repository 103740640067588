
import { Component, Prop, toNative, Vue } from 'vue-facing-decorator';
import loading from '@/components/Common/loading.vue';
import { SignalRModelRequest } from '@/signalRRequest/SignalRModelRequest';
import { StoreHelper } from '@/store/StoreHelper';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import { NotificationFolderSignalRModel } from '@/signalRRequest/Notification/NotificationFolderSignalRModel';
import { FetchData, Root, Store } from '@/main';
import { reactive } from 'vue';
import { minLength, required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
@Component({
  components: {
    loading,
  },
})
class NotificationFolderEditItemPane extends Vue {
  public showLoading = false;
  public loaderBorderColor?: string = '';
  @Prop() private selectedFolderItem?: string;
  @Prop() private applicationborderColor?: string;

  private state = reactive({
    localFolderItem: '',
  });

  private rules = {
    localFolderItem: {
      required,
      minLength: minLength(0),
    },
  };
  private status(validation: { $error: any; $dirty: any }) {
    return {
      error: validation.$error,
      dirty: validation.$dirty,
    };
  }
  private v$ = useVuelidate(this.rules, this.state);
  private mounted() {
    this.state.localFolderItem = JSON.parse(JSON.stringify(this.selectedFolderItem!));
  }
  private Cancel(val: any) {
    val.$reset();
    this.$emit('notification-folder-item-cancel-operation', this.selectedFolderItem);
  }
  private GetPlaceholder(): string {
    return this.$t('Folder.Placeholder').toString();
  }
  private SendNotificationRequest(): SignalRModelRequest {
    setTimeout(() => {
      Root.emit('show-notification-popup', true);
      Root.emit('show-notification-loading', true);
    }, 100);
    const signalR: NotificationFolderSignalRModel = new NotificationFolderSignalRModel(
      Root,
      Store,
      this.$t,
    );
    let signalObject: SignalRModelRequest = new SignalRModelRequest();
    signalObject = signalR.SingleUpdateFolderItemSignalRModelRequest;
    signalObject.Description = this.$t(signalObject.SubmitMessage, {
      user: signalR.GetUserName(signalObject, this.$t('UserName.You').toString()),
    }).toString();

    const storeHelper: StoreHelper = new StoreHelper(Store);
    const reqInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();
    signalObject.ApplicationId = Number(reqInfo.ApplicationId);
    signalObject.EventId = Number(reqInfo.ApplicationInstanceId);
    signalObject.JSONData = JSON.stringify({
      selectedName: this.state.localFolderItem, // Name to de dispalyed in Notification Popup
    });
    signalR.AddToNotificationPopup(signalObject);
    return signalObject;
  }
  private SendNotificationRequestToQueue(
    signalObject: SignalRModelRequest,
  ) {
    const signalR: NotificationFolderSignalRModel = new NotificationFolderSignalRModel(
      Root,
      Store,
      this.$t,
    );
    signalObject.SelectedIds = [0];
    signalObject.JSONData = JSON.stringify(
      {
        updatedName: this.state.localFolderItem,
        oldName: this.selectedFolderItem,
        selectedName: this.state.localFolderItem, // Name to de dispalyed in Notification Popup
      });
    signalR.PerformFolderSingleOperation(signalObject);
  }
  private async Submit(val: any) {
    val.$touch();
    const res = !await val.$validate();
    if(res) {
      return;
    }
    val.$reset();
    // No errors submitting.
    if (this.applicationborderColor != null) {
      this.loaderBorderColor = this.applicationborderColor;
    }
    const signalObject = this.SendNotificationRequest();
    FetchData('/Notification/UpdateNotificationGroup', JSON.stringify({
      oldGroupName : this.selectedFolderItem,
      newGroupName : this.state.localFolderItem,
    }))
      .then(() => {
        // Successful update / save
        this.$emit(
          'notification-folder-item-edit-update-operation',  // for closing Edit pane
          this.state.localFolderItem,
        );
        this.SendNotificationRequestToQueue(
          signalObject,
        );

      })
      .catch(() => {
        // Log error if required
      });
  }
}
export default toNative(NotificationFolderEditItemPane);
